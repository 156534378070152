@import url(https://fonts.bunny.net/css?family=roboto:300,700|roboto-condensed:700);
@import "assets/variables";
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Page {
  background: var(--greydark);
}

body {
  padding: 0;
  margin: 0;
  font-size: 17px;
  color: white;
  font-family: "Roboto", Helvetica, sans-serif;
  font-weight: 300;
  background: var(--grey);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

:root {
  --grey: #181c1e;
  --greydark: #0f1112;
}

.wrapper {
  overflow-x: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  height: 100vh;
  &:after {
    pointer-events: none;

    content: "";
    top: 0;
    left: 0;
    z-index: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    mix-blend-mode: multiply;
  }
}

.swiper-slide img {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  margin: 0;
  padding: 0;
}

.main {
  min-height: 100vh;
}

.footer {
  z-index: 10;
  position: absolute;
  top: 1rem;
  padding: 2rem;
  color: #fff;
  justify-content: center;
  font-weight: bold;
  @media (max-width: 768px) {
    padding: 1rem;
  }
  ul {
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: center;
    opacity: 0.5;
    li {
      padding: 0.5rem;
      list-style: none;
      letter-spacing: 0.3rem;
      text-transform: uppercase;
      font-size: 12px;
      text-decoration: none;
      color: #fff;
      a {
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        font-size: 0.75rem;
        font-size: 12px;

        text-decoration: none;
        color: #fff;
      }
    }
  }
}

.contact {
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  font-size: 12px;
  text-decoration: none;
  color: #fff;
  z-index: 2;
  top: 1rem;
  right: 1rem;
  left: 1rem;
  color: #fff;
  line-height: 1.5;
  text-align: center;

  a {
    color: #fff;
  }
}

.content {
  padding: 100px 2rem;
  .wrap {
    max-width: 800px;
    margin: 0 auto;
  }
  ul {
    li {
      margin-bottom: 0.5rem;
    }
  }
  h1 {
    font-weight: normal;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 3rem;
    line-height: 1;
    color: white;
    text-transform: uppercase;
    @media (max-width: $mobile) {
      font-size: 2rem;
    }
  }
  p {
    line-height: 1.8;
  }
  .back {
    font-family: "Roboto";
    font-size: 0.75rem;
    color: #666;
  }
}

.datepicker {
  position: fixed;
  z-index: 90;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  width: 100%;
  left: 0;
  background-color: rgb(0, 0, 0, 0.7);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  .row {
    position: relative;
    max-width: 700px;
    margin: 0 auto;
    .inner {
      display: flex;
      display: grid;
      grid-template-columns: 1fr 1fr 0.5fr 1fr;
      @media (max-width: 768px) {
        grid-template-columns: 1fr 1fr 0.5fr;
      }
      .grp {
        min-width: 0; /* NEW; needed for Firefox */
        border-bottom: 1px solid #fff;
        border-right: 1px solid #fff;

        &:first-child {
          border-left: 1px solid #fff;
        }
        &.select {
          border-top: 1px solid #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            height: 1.2rem;
            margin-left: 0.25rem;
          }
          select {
            background: transparent;
            color: #fff;
            border: 0px;
          }
        }
        label {
          font-weight: bold;
          color: #fff;
          margin-top: -0.5rem;
          display: block;
          padding-left: 1rem;
          position: relative;
          font-size: 0.75rem;
          text-transform: uppercase;
          overflow: hidden;

          &:before {
            content: "";
            height: 1px;
            background: #fff;
            display: block;
            position: absolute;
            left: 0;
            z-index: 999;
            width: 0.5rem;
            top: 0.5rem;
          }
          &:after {
            content: "";
            height: 1px;
            background: #fff;
            display: block;
            position: absolute;
            z-index: 999;
            width: 100%;
            top: 0.5rem;
            right: 0;
            left: 5rem;
          }
        }
      }
      input {
        padding: 0.5rem 1rem;
        font-size: 1rem;
        letter-spacing: 0.5rem;
        font-weight: bold;
        color: #fff;
        appearance: none;
        max-width: 100%;
        box-sizing: border-box;
        border: 0px;
        border-radius: 0;
        background: transparent;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: "Ubuntu", Helvetica, sans-serif;

        @media (max-width: 768px) {
          letter-spacing: 0.2rem;
          padding: 0.5rem 0.5rem;
          margin: 0;
        }
      }
      .grp-button {
        width: 100%;
        overflow: hidden; /* NEW */
        min-width: 0; /* NEW; needed for Firefox */
        button {
          margin: 0;
        }
        @media (max-width: 768px) {
          grid-column-start: 1;
          grid-column-end: 4;
        }
      }
      button {
        justify-content: center;
        text-align: center;
        margin: 0 1rem;
        width: 100%;
        height: 100%;
        font-weight: bold;
        padding: 0.75rem 1rem;
        font-size: 1rem;
        color: #fff;
        appearance: none;
        line-height: 1;
        cursor: pointer;
        background: transparent;
        border-radius: 0px;
        border: 0px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        margin-right: 0;
        transition: all 0.3s ease;
        font-family: "Ubuntu", Helvetica, sans-serif;
        &:hover {
          transition: all 0.3s ease;

          color: #fff;
          background: #000;
        }
      }
    }
    .picker {
      position: absolute;
      bottom: calc(100% + 1rem);
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

#Footer {
  background: #000;
  padding-bottom: 100px;
  ul {
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: center;
    flex-wrap: wrap;

    li {
      display: block;
      padding: 1rem;
      list-style: none;
      a {
        text-decoration: none;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        font-size: 0.75rem;
        font-size: 12px;
        font-weight: bold;
        text-decoration: none;
        color: #fff;
      }
    }
  }
  display: flex;
  justify-content: center;
}

.Block {
  min-height: 50vh;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: stretch;
  justify-content: center;
  background: var(--grey);
  color: white;
  .content {
    background-size: cover;
  }
  @media (max-width: $mobile) {
    grid-template-columns: 100%;
  }
  .slick-slider {
    div {
      height: 100%;
      img {
        object-fit: cover;
      }
    }
  }
  .link {
    display: inline-block;
    padding: 0.2rem 1rem;
    background: white;
    color: var(--grey);
    border-radius: 3px;
    text-decoration: none;
    font-weight: bold;
    transition: all 0.3s ease;

    &:hover {
      transition: all 0.3s ease;
      background: #ccc;
      color: #000;
    }
  }
  &.align-Links {
    .bild {
      img {
        max-height: 100%;
      }
      grid-column: 1;
      grid-row: 1;
      @media (max-width: $mobile) {
        grid-row: 1;
      }
    }
    .content {
      grid-column: 2;
      grid-row: 1;
      @media (max-width: $mobile) {
        grid-column: 1;
        grid-row: 2;
      }
    }
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    justify-content: center;

    line-height: 1.8;
    width: 100%;
    box-sizing: border-box;
    padding: 8rem 4rem;

    .inner {
      max-width: 700px;
    }

    h2 {
      color: white;
      text-transform: uppercase;
      font-family: "Roboto Condensed", sans-serif;
      font-size: 2.5rem;
      margin: 0;
      line-height: 1;
      @media (max-width: $mobile) {
        font-size: 2rem;
      }
    }
  }
  .bild {
    position: relative;
    .slick-dots {
      bottom: 1rem;
    }
    .slick-next {
      right: 1rem;
    }
    .slick-prev {
      z-index: 9999;
      left: 1rem;
    }
    .slick-slide {
      div {
        position: relative;
        width: 100%;
      }
    }
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    @media (max-width: $mobile) {
      grid-row: 1;
      min-height: 300px;
    }
  }
}

.Text {
  margin: 0 auto;
  line-height: 1.8;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background: var(--greydark);
  color: white;
  .inner {
    padding: 1rem;

    max-width: 768px;
    margin: 0 auto;
    img {
      object-fit: cover;
      margin: 0 auto;
      display: block;
      max-width: 100%;
    }
  }
  h2 {
    color: white;
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 3rem;
    line-height: 1;
    @media (max-width: $mobile) {
      font-size: 2rem;
    }
    margin: 0;
  }
}

.Galerie {
  background: var(--greydark);
  max-width: 1024px;
  margin: 0 auto;
  img {
    object-fit: cover;
  }
}

@media only screen and (max-width: 40em) {
  thead th:not(:first-child) {
    display: none;
  }

  td,
  th {
    display: block;
  }

  td[data-th]:before {
    content: attr(data-th);
  }
}

.Imagegrid {
  background: var(--greydark);
  padding: 100px 0;
  .inner {
    max-width: 1200px;
    padding: 1rem;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 1rem;
    img {
      background: #fff;
      height: 450px;
      object-fit: contain;
      width: 100%;
    }
  }
}

.trustyou {
  border: none;
  position: absolute;
  z-index: 9999;
  right: 2rem;
  bottom: 6rem;
  @media (max-width: $mobile) {
    right: auto;
    left: 1rem;
    top: 5rem;
    bottom: 8rem;
    transform: scale(0.6);
    transform-origin: top left;
  }
}

.shop {
  position: fixed;
  top: 5rem;
  right: 1rem;
  z-index: 99999999;

  img {
    height: 150px;
    width: 150px;
    @media (max-width: $mobile) {
      height: 100px;
      width: 100px;
    }
  }
}

.country-selector {
  background: white !important;
  border: 0px !important;
  top: 5rem !important;
  bottom: auto !important;
  right: 0 !important;
  a {
    padding-right: 20px !important;
  }
  .wg-flag {
    margin: 0 !important;
  }
  > div {
    border: 0px !important;
  }
  .wgcurrent:after {
    right: 5px !important;
  }
}

.top-menu {
  position: relative;
  .children {
    top: 100%;
    left: 0;
    min-width: 200px;
    z-index: 9999;
    a {
      width: 100%;
      box-sizing: border-box;
    }
    background: #000;
    display: none;
    position: absolute;
    @media (max-width: $mobile) {
      left: 50%;
      transform: translateX(-50%);
      a {
        text-align: center;
        background: #333;
        justify-content: center;
      }
    }
  }
}

.top-menu:hover {
  .children {
    display: block;
  }
}

.gs-widget-container.gs-position-bottom-right {
  @media (max-width: $mobile) {
    top: 11rem;
    bottom: auto;
    left: 7px !important;
  }
}

p a {
  color: white;
}

// facebook instagram
