@import "assets/variables";

.Header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  box-sizing: border-box;
  background: black;
  :global {
    .Nav {
      display: flex;

      @media (max-width: $mobile) {
        display: none;
        &.menu-visible {
          display: flex;
          position: fixed;
          width: 100%;
          align-items: center;
          justify-content: center;
          height: 100%;
          background: #000;
          flex-direction: column;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 999999;
        }
      }
      .top-menu,
      a {
        font-weight: bold;
        color: #fff;
        text-decoration: none;
        padding-left: 1rem;
        padding-right: 1rem;
        text-transform: uppercase;
        letter-spacing: 0.3rem;
        font-size: 0.75rem;
        height: 50px;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        &:hover {
          background: #fff;
          color: #000;
        }
        &.active {
          &:after {
            height: 3px;
            content: "";

            width: 80%;
            transform: translateX(-50%);
            left: 50%;
            position: absolute;
            bottom: 5px;
            background: #fff;
          }
        }
      }
    }
    .button-grp {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      .voucher {
        background: #000;
        color: #fff;
        border: 1px solid #fff;
        background-image: url("../../../assets/present.svg");
        background-repeat: no-repeat;
        background-size: 1.5rem;
        background-position: 1rem center;
        padding-left: 3rem;
        @media (max-width: 1200px) {
          display: flex;
          font-size: 0;
          width: 50px;
          background-position: center;
        }
        filter: invert(0);
        transition: filter 0.2s ease;
        &:hover {
          transition: filter 0.2s ease;

          filter: invert(1);
        }
        div {
          @media (max-width: 1200px) {
            display: flex;
            font-size: 0;
            width: 50px;
            background-position: center;
          }
        }
      }
    }
    .nav-toggle {
      height: 50px;
      width: 50px;
      z-index: 9999999999;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 1rem;
      top: 1rem;
      border: none;
      margin: 0;
      width: auto;
      overflow: visible;
      background: transparent;

      /* inherit font & color from ancestor */
      color: inherit;
      font: inherit;
      /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
      line-height: normal;

      /* Corrects font smoothing for webkit */
      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;

      /* Corrects inability to style clickable `input` types in iOS */
      -webkit-appearance: none;
      /* Remove excess padding and border in Firefox 4+ */
      &::-moz-focus-inner {
        border: 0;
        padding: 0;
      }
      img {
        height: 36px;
        width: auto;
      }
      @media (min-width: $mobile) {
        display: none;
      }
    }
    .booklink {
      background: white;
      color: black;
      padding: 0.5rem 1rem;
      border-radius: 3px;
      box-sizing: border-box;
      letter-spacing: 0.2rem;
      font-size: 0.75rem;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      background-image: url("../../../assets/calendar.svg");
      background-repeat: no-repeat;
      background-size: 1.5rem;
      background-position: 1rem center;
      padding-left: 3rem;
      @media (max-width: 1280px) {
        display: flex;
        font-size: 0;
        width: 50px;
        background-position: center;
      }

      filter: invert(0);
      transition: filter 0.2s ease;
      &:hover {
        transition: filter 0.2s ease;

        filter: invert(1);
      }
      @media (max-width: $mobile) {
        position: fixed;
        bottom: 2rem;
        font-size: 0.75rem;
        width: auto;
        left: 50%;
        transform: translateX(-50%);
        background-position: 1rem;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
      }
      text-decoration: none;
    }
    .logo-small {
      height: 32px;
    }
  }
}
