@import "assets/variables";

.Hero {
  height: 100vh;
  position: relative;
  :global {
    .batschari-slider {
      &:before {
        content: "";
        background: rgba(0, 0, 0, 0.1);
        height: 100vh;
        width: 100vw;
        z-index: 9999;
        position: absolute;
      }
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;

      .batschari-slide {
        height: 100vh;
        width: 100vw;
        object-fit: cover;
        object-position: center;
      }
    }
    .white-box {
      width: 800px;
      padding: 8rem;
      position: absolute;
      max-width: 100%;
      z-index: 2;
      box-sizing: content-box;
      padding-top: 50px;
      color: #fff;
      padding-bottom: 50px;
      font-weight: bold;
      z-index: 99999;
      top: 50%;
      text-transform: uppercase;
      line-height: 1.5;
      animation: fadein 2s;

      p {
        margin: 0;
        font-weight: normal;
        letter-spacing: 0.2rem;
        font-size: 1rem;
        &:nth-child(3) {
          line-height: 1;
          font-size: 6rem;
          letter-spacing: 0;
          text-transform: none;
          font-family: "Roboto Condensed";
          @media (max-width: $mobile) {
            font-size: 2.5rem;
          }
        }
      }
      font-size: 2rem;
      @media (max-width: 800px) {
        width: 70%;
        padding: 2rem;
      }
      img {
        max-width: 100%;
        height: auto;
        max-height: 300px;
        margin-bottom: 3rem;
      }
    }
  }

  .subline {
    font-size: 1rem;
    letter-spacing: 0.2rem;
  }
  .headline {
    margin-top: 1rem;
    font-weight: 300;
    :global {
      em {
        font-style: normal;
        font-family: "Roboto Condensed";
      }
      p {
        font-size: 5rem;
        line-height: 1;
        font-weight: 100;
        @media (max-width: $mobile) {
          font-size: 2.5rem;
        }
      }
    }
  }
}

.sidebar {
  top: 50%;
  position: absolute;
  right: 0;
  transform: translateY(-50%);
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  z-index: 99999;
  transform-origin: top right;
  @media (max-width: 1024px) {
    transform: scale(0.7) translateY(-50%);
  }
  :global {
    img {
      height: 2.5rem;
      margin-bottom: 0.5rem;
    }
    a {
      text-decoration: none;
      font-size: 0.75rem;
      letter-spacing: 0.2rem;
      text-transform: uppercase;
      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
      font-weight: 300;
      text-align: center;
      padding: 0.5rem;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      display: flex;
      flex-direction: column;
      color: #fff;
      border-bottom: 1px solid #fff;
    }
  }
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
}
